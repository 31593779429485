.navbar {
  .nav-link {
    font-weight: $navbar-font-weight;
    text-transform: $navbar-link-text-transform;
  }
}

.navbar-brand {
  position: relative;
  width: 7rem;
  padding:  0 0;
  margin: 0.3rem 0;

  .logo-dark{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: auto;
    visibility: hidden;
  }
}
body.dark-mode{
 .navbar-brand{
  .logo-dark{
    opacity: 1;
    visibility: visible;
  }
  .logo-light{
    opacity: 0;
    visibility: hidden;
  }
 }
}
.navbar-transparent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: $zindex-fixed;
  transition: transform .3s linear, background-color .15s, padding .25s, box-shadow .15s;
  padding: .5rem 0;

  &.navbar-sticky {
    position: fixed;
    left: 0;
    top: 0;
  }
}

.headroom--not-top {
  &.navbar-light {
    background-color: var(--#{$prefix}body-bg);
  }
  &.navbar-dark {
    background-color: var(--#{$prefix}black);
  }
}

.headroom--pinned:not(.headroom--top),
.headroom--bottom {
  box-shadow: $navbar-sticky-box-shadow;
}

// scss-docs-start navbar-expand-loop
// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    // stylelint-disable-next-line scss/selector-no-union-class-name
    &#{$infix} {
      @include media-breakpoint-up($next) {
        .navbar-brand{
          max-width:7rem;
        }
        &.headroom--top {
          padding: 1.25rem 0;
        }
        &.navbar-transparent.headroom--not-top,&.navbar-transparent.headroom--bottom {
          padding: 0 0;
          
        }
        .offcanvas {
          position: static;
          z-index: auto;
          flex-grow: 1;
          width: auto !important;
          height: auto !important;
          visibility: visible !important;
          background-color: transparent !important;
          border: 0 !important;
          transform: none !important;
          transition: none;
          display: flex;
          align-items: center;
          flex-direction: row;

          .offcanvas-header {
            display: none;
          }

          .offcanvas-body {
            display: flex;
            flex-grow: 1;
            padding: 0;
            overflow-y: visible;
          }
        }

        &.headroom--unpinned:not(.headroom--top):not(.headroom--bottom) {
          transform: translateY(-100%);
        }

        .nav-item {
          height: 4.5rem;
          display: flex;
          align-items: center;

          &:not(:first-child) {
            margin-left: .5rem;
          }
        }
      }
    }
  }
}

// scss-docs-end navbar-expand-loop

//Night mode icons
.dark-mode-checkbox{
  cursor: pointer;
  input{
    appearance: none;
  }
  .dark-mode-icons{
    span:last-child{
      display: none;
    }
  }
  input[type=checkbox]:checked~.dark-mode-icons{
    span:last-child{
      display: block;
    }
    span:first-child{
      display: none;
    }
  }
}
.navbar-dark{
  .offcanvas{
    background-color: $gray-800;
    color: $gray-300;
  }
}